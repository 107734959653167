import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import GotoDemoHero from 'components/hero/gotoDemo'
import { enterpriseSupportFeatures } from '/static/data/solution.static'

const EnterpriseSupportPage = () => {
  const ENTERPRISE_SUPPORT_FEATURES = enterpriseSupportFeatures()
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('enterpriseSupportPage.meta.title'),
            metaDescription: t('enterpriseSupportPage.meta.description'),
            metaUrl: 'https://www.mlytics.com/features/enterprise-support/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className="container flex flex-col-reverse md:flex-row gap-5 pb-32 pt-24">
          <div className="flex-1">
            <h1 className="font-normal mb-5 text-primary-400 text-lg">
              {t('enterpriseSupportPage.section1.subtitle')}
            </h1>
            <h2 className="leading-snug text-primary-500 text-4xl lg:text-5xl">
              {t('enterpriseSupportPage.section1.title')}
            </h2>
            <p className="py-10 text-xl text-gray-500">{t('enterpriseSupportPage.section1.description')}</p>
            <div className="flex flex-col lg:flex-row gap-3 my-1">
              <Link to="/signup/" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                {t('button.freeTrial.0')}
              </Link>
              <Link
                to="/lp/get-a-demo/"
                className="btn btn-bold btn-ghost btn-orange inline-block"
                style={{ width: 208 }}
              >
                {t('button.getDemo.0')}
              </Link>
            </div>
          </div>
          <div className="flex flex-1 items-center justify-center">
            <img
              src="https://www.mlytics.com/wp-content/uploads/2021/08/Security-Operations-Center_hero_01.svg"
              alt={t('enterpriseSupportPage.section1.subtitle')}
              style={{ height: 'auto', maxWidth: '550px' }}
            />
          </div>
        </section>
        <section className="bg-primary-500 px-4 py-20">
          <div className="container">
            <h2 className="text-center text-white text-4xl">{t('enterpriseSupportPage.section2.title')}</h2>
            <p className="leading-7 m-auto max-w-[800px] pt-6 text-center text-white">
              {t('enterpriseSupportPage.section2.description.0')}
              <br />
              <br />
              {t('enterpriseSupportPage.section2.description.1')}
            </p>
          </div>
        </section>
        <section className="bg-primary-500/5 py-20">
          <div className="container grid grid-cols-1 lg:grid-cols-3 gap-5">
            {ENTERPRISE_SUPPORT_FEATURES.map((feature) => (
              <div key={feature.title} className="text-center">
                <img src={feature.imageSrc} alt={t(feature.title)} className="mx-auto" />
                <h2 className="text-primary-500 text-2xl">{t(feature.title)}</h2>
                <p className="my-5 text-gray-700">{t(feature.content)}</p>
              </div>
            ))}
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row-reverse gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <StaticImage
                src="https://www.mlytics.com/wp-content/uploads/2022/10/Security-Operations-Center_Managed-DDoS-protection_01.webp"
                alt={t('enterpriseSupportPage.section4.title')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('enterpriseSupportPage.section4.subtitle')}</div>
              <h2 className="text-3xl">{t('enterpriseSupportPage.section4.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('enterpriseSupportPage.section4.description')}</p>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2021/08/Security-Operations-Center_Highly-catered_01.svg"
                alt={t('enterpriseSupportPage.section5.title')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('enterpriseSupportPage.section5.subtitle')}</div>
              <h2 className="text-3xl">{t('enterpriseSupportPage.section5.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('enterpriseSupportPage.section5.description')}</p>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row-reverse gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="	https://www.mlytics.com/wp-content/uploads/2021/08/Security-Operations-Center_24x7-global-support_01.svg"
                alt={t('enterpriseSupportPage.section6.title')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('enterpriseSupportPage.section6.subtitle')}</div>
              <h2 className="text-3xl">{t('enterpriseSupportPage.section6.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('enterpriseSupportPage.section6.description')}</p>
            </div>
          </div>
        </section>
        <GotoDemoHero />
      </React.Fragment>
    </Layout>
  )
}

export default EnterpriseSupportPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
